<template>
  <div id="app">
    <div class="nutrient_msg">
      <div class="bank_sub_title">
        <span>
          <font class=""></font>升级前后对比
        </span>
      </div>
      <div class="nutrient_msg_b dark" style="padding:0">
        <table>
          <tr>
            <th width="33%" align="center" valign="middle"></th>
            <th width="33%" align="center" valign="middle">当前</th>
            <th width="33%" align="center" valign="middle">升级后</th>
          </tr>
          <tr>
            <td align="center" valign="middle">零钱上限</td>
            <td align="center" valign="middle">2000元</td>
            <td align="center" valign="middle">1万元</td>
          </tr>
          <tr>
            <td align="center" valign="middle">日限额</td>
            <td align="center" valign="middle">2000元</td>
            <td align="center" valign="middle">1万元</td>
          </tr>
          <tr>
            <td align="center" valign="middle">年限额</td>
            <td align="center" valign="middle">5万元</td>
            <td align="center" valign="middle">20万元</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="bank_tips">
      <div class="bank_tips_b">
        升级前请准备好账户所有人的身份证并确保手机在身边，以便接收银行预留手机的验证短信。
      </div>
    </div>

    <div class="height_5vw"></div>

    <div class="bank_btn">
      <button class="" @click="routeToAccountUpgrade">
        升级限额
      </button>
      <button class="line gray" @click="goBack">返回</button>
    </div>
  </div>
</template>


<style scoped>
#app {
  background: #FFF;
  height: 100vh;
  overflow: scroll;
  position: relative;
  z-index: 2000;
}

table {
  margin-bottom: 0;
}

table tr {
  border: none;
}

</style>

<script>
import Vue from 'vue'
import {post} from "@/utils/http";
import {getRealLength} from "@/utils/util";
import Notify from "vant/es/notify";
import {Dialog} from 'vant';

export default {
  data() {
    return {};
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    goBack() {
      this.$router.push('/recharge');
      window.history.pushState('forward', null, '');
      window.history.forward(1);
    },
    routeToAccountUpgrade() {
      this.$router.push({path: "/AccountUpgrade"})
    },

  }
};
</script>
